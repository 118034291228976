<template>
  <div class="main absolute block w-screen h-screen">
    <div>
      <div
        class="
          mx-0
          lg:m-32
          mt-48
          lg:mt-24
          w-full
          lg:w-10/12
          bg-white
          absolute
          rounded-3xl
          overflow-hidden
          table
        "
      >
        <div class="header w-full">
          <div
            class="
              absolute
              rounded-t-3xl
              bg-gradient-to-r
              from-blue-900
              to-gray-800
              p-4
              shadow
              w-full
              text-2xl text-white
            "
          >
            <div class="absolute inline-table left-0 ml-5">
              <i class="fas fa-question-circle pr-3"></i>
            </div>
            <h3 class="relative inline-block font-bold">Cuestionario</h3>
            <div class="absolute inline-table right-0 mr-10">
              <button
                type="button"
                data-dismiss="modal"
                @click="closeModalCuestionario"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="body p-5">
          <div
            id="cont_Cuestionario"
            class="mt-16 grid grid-cols-2 gap-2 md:gap-6 h-96"
          >
            <div
              id="cont_Pregunta"
              class="relative inline-block"
              v-for="pregunta in preguntasCuestionario"
              :key="'P' + pregunta.idPregunta"
            >
              <span class="relative block w-full text-left font-bold z-50">{{
                pregunta.descripcionPregunta + ":"
              }}</span>
              <div
                class="cont_Opciones my-1"
                v-for="opcion in opcionesCuestionario(pregunta.idPregunta)"
                :key="'O' + opcion.idOpcion"
              >
                <input
                  class="mr-2"
                  type="radio"
                  :name="opcion.idPregunta"
                  :id="opcion.idOpcion"
                  :value="opcion.idOpcion"
                  @change="setRespuesta(pregunta.idPregunta, opcion.idOpcion)"
                />
                <label class="z-50" :for="opcion.idOpcion">{{
                  "(" + opcion.letraOpcion + ")." + opcion.descripcionOpcion
                }}</label>
              </div>
              <textarea
                class="Observacion"
                style="display: none"
                :id="'obs' + pregunta.idPregunta"
                name=""
                cols="30"
                rows="10"
                placeholder="Observacion..."
              ></textarea>
            </div>
            <div id="cont_Pregunta" class="relative inline-block">
              <span class="relative block w-full text-left font-bold">{{
                "Notas Adicionales:"
              }}</span>
              <textarea
                class="NotasAdicionales"
                v-model="notasAdcionales"
                name=""
                cols="30"
                rows="10"
                placeholder="Escribe Aqui Notas Adicionales..."
              ></textarea>
            </div>
          </div>
          <div class="cont_botones">
            <div
              class="
                search-box
                relative
                inline-block
                mx-auto
                inline-block
                my-auto
                w-full
              "
            >
              <span class="absolute label-motivo font-bold">Motivo:</span>
              <select
                id="buscarzona"
                v-model="motivoCuestionario"
                class="border rounded-xl shadow h-9 bg-white w-full"
              >
                <option disabled selected>Motivos</option>
                <option
                  v-for="(motivo, index) in motivos"
                  :value="motivo"
                  :key="index"
                >
                  {{ motivo.descripcionMotivo }}
                </option>
              </select>
            </div>
          </div>
          <div class="cont_botones" id="ocultar_guardar">
            <button @click="guardarCuestionario()">
              <i class="fas fa-save mr-3"></i>Guardar Cuestionario
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/logic/auth";
import { mapMutations, mapState } from "vuex";
export default {
  name: "Cuestionario",
  props: {
    idClienteCuestionario: undefined,
    motivos: {},
  },
  data() {
    return {
      idOpcionRespuestas: [],
      notasAdcionales: undefined,
      motivoCuestionario: {},
    };
  },
  methods: {
    ...mapMutations(["closeModalCuestionario"]),

    opcionesCuestionario(idPregunta) {
      return this.estructuraCuestionario.filter(
        (x) => x.idPregunta === idPregunta
      );
    },
    setRespuesta(idPregunta, idOpcion) {
      let mostrarObs = [1, 2, 3, 4, 6, 7, 10, 11];

      let filtradoObs = mostrarObs.filter((x) => x === idOpcion);

      if (filtradoObs.length > 0) {
        document.getElementById("obs" + idPregunta).style.cssText =
          "display: block;";
      } else {
        document.getElementById("obs" + idPregunta).style.cssText =
          "display: none;";
      }

      if (this.idOpcionRespuestas.length == 0) {
        this.idOpcionRespuestas.push({
          idPregunta: idPregunta,
          idOpcion: idOpcion
        });
      } else {
        let filtrado = this.idOpcionRespuestas.filter(
          (x) => x.idPregunta === idPregunta
        );
        if (filtrado.length == 0) {
          this.idOpcionRespuestas.push({
            idPregunta: idPregunta,
            idOpcion: idOpcion
          });
        } else {
          for (var i = 0; i < this.idOpcionRespuestas.length; i++) {
            if (this.idOpcionRespuestas[i].idPregunta == idPregunta) {
              this.idOpcionRespuestas[i].idPregunta = idPregunta;
              this.idOpcionRespuestas[i].idOpcion = idOpcion;
            }
          }
        }
      }
    },
    guardarCuestionario() {
        var guardar = false
        if(this.idOpcionRespuestas.length < 5){
            guardar = false
            this.$alert(
                "Por favor complete todo el cuestionario!",
                "Verificar Informacion!",
                "error"
            );
        }else{
            if(this.motivoCuestionario.numCodigo == undefined){
                guardar = false
                this.$alert(
                    "Por favor Ingrese un Motivo!",
                    "Verificar Informacion!",
                    "error"
                );
            }else{
                guardar = true
            }
        } 

        if(guardar == true){
            if(this.notasAdcionales == undefined){
                this.notasAdcionales = ""
            }

            document.getElementById("ocultar_guardar").style.cssText = "display: none;"

            let user = auth.getUserLogged();
            user = JSON.parse(user);

            let EncCuestionario = {
                idCliente: this.idClienteCuestionario,
                motivo: this.motivoCuestionario.numCodigo,
                notasAdicionales: this.notasAdcionales,
                usuarioIngreso: user.loginUsuario,
                fechaCreacion: new Date(),
                campañaIngreso: this.permisosUsuario.campañaActual,
            }

            axios
                .post(this.$hostname + "/Api/EncCuestionarioNuevas", EncCuestionario, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + user.token,
                    },
                })
                .then((result) => {
                    //console.log(result)
                    var numCodigoEncCuestionario = result.data.data.numCodigo

                    let DetCuestionario = [
                        {
                            idCuestionario: numCodigoEncCuestionario,
                            idPregunta: this.idOpcionRespuestas[0].idPregunta,
                            idOpcion: this.idOpcionRespuestas[0].idOpcion,
                            observacion: document.getElementById('obs' + this.idOpcionRespuestas[0].idPregunta).value,
                        },
                        {
                            idCuestionario: numCodigoEncCuestionario,
                            idPregunta: this.idOpcionRespuestas[1].idPregunta,
                            idOpcion: this.idOpcionRespuestas[1].idOpcion,
                            observacion: document.getElementById('obs' + this.idOpcionRespuestas[1].idPregunta).value,
                        },
                        {
                            idCuestionario: numCodigoEncCuestionario,
                            idPregunta: this.idOpcionRespuestas[2].idPregunta,
                            idOpcion: this.idOpcionRespuestas[2].idOpcion,
                            observacion: document.getElementById('obs' + this.idOpcionRespuestas[2].idPregunta).value,
                        },
                        {
                            idCuestionario: numCodigoEncCuestionario,
                            idPregunta: this.idOpcionRespuestas[3].idPregunta,
                            idOpcion: this.idOpcionRespuestas[3].idOpcion,
                            observacion: document.getElementById('obs' + this.idOpcionRespuestas[3].idPregunta).value,
                        },
                        {
                            idCuestionario: numCodigoEncCuestionario,
                            idPregunta: this.idOpcionRespuestas[4].idPregunta,
                            idOpcion: this.idOpcionRespuestas[4].idOpcion,
                            observacion: document.getElementById('obs' + this.idOpcionRespuestas[4].idPregunta).value,
                        },
                    ]

                    axios
                        .post(this.$hostname + "/Api/DetCuestionarioNuevas", DetCuestionario, {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + user.token,
                            },
                        })
                        .then((result) => {
                            //console.log(result)
                            this.$alert(
                                "Se grabó el cuestionario con el siguiente ID: #" + numCodigoEncCuestionario,
                                "Éxito!",
                                "success"
                            )
                            this.closeModalCuestionario()
                        })
                        .catch((err) => {
                            console.log(err)
                            this.$alert(
                                "Por favor vuelve a ingresar a la plataforma",
                                "Expiro la Sesion!",
                                "error"
                            );
                            this.$router.push("/");
                        });
                })
                .catch((err) => {
                    console.log(err)
                    this.$alert(
                        "Por favor vuelve a ingresar a la plataforma",
                        "Expiro la Sesion!",
                        "error"
                    );
                    this.$router.push("/");
                });
        }
    },
  },
  created() {},
  computed: {
    ...mapState(["permisosUsuario","estructuraCuestionario"]),
    preguntasCuestionario() {
      let preguntas = [];

      console.log(this.estructuraCuestionario)

      this.estructuraCuestionario.forEach((element) => {
        if (preguntas.length == 0) {
          preguntas.push({
            idPregunta: element.idPregunta,
            descripcionPregunta: element.descripcionPregunta,
          });
        } else {
          let filtrado = preguntas.filter(
            (x) => x.idPregunta === element.idPregunta
          );
          if (filtrado.length == 0) {
            preguntas.push({
              idPregunta: element.idPregunta,
              descripcionPregunta: element.descripcionPregunta,
            });
          }
        }
      });
      return preguntas;
    },
  },
};
</script>

<style>
*:focus {
  outline: none;
}
.main {
  background: rgba(0, 0, 0, 0.5);
}
.body {
    height: 40rem;
}
.cont_Opciones {
  display: table;
}
.Observacion {
  position: absolute;
  border: 1px solid black;
  border-radius: 0.3rem;
  padding: 0.5rem;
  background: white;
  top: 30%;
  right: 0;
  height: 70%;
  font-size: 13px;
  width: 50%;
  z-index: 40;
}
.NotasAdicionales {
  position: absolute;
  border: 1px solid black;
  border-radius: 0.3rem;
  padding: 0.5rem;
  background: white;
  top: 30%;
  left: 0%;
  height: 70%;
  font-size: 13px;
  width: 100%;
  z-index: 40;
}
textarea:focus {
  border: 1px solid #1e3a8a;
}
.cont_botones {
  position: relative;
  display: inline-flex;
  height: 4rem;
  margin: 1rem 1rem 0rem;
  width: 24rem;
  bottom: -4rem;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
}
.cont_botones button {
  background: hsl(120deg 53% 50%);
  color: white;
  padding: 0.3rem 1rem;
  font-weight: 700;
  border-radius: 0.5rem;
  border-bottom: 0.3rem solid #3bed3b;
  transition: 0.3s ease-in-out;
}
.cont_botones button:hover {
  background: hsl(120deg 100% 35%);
  padding: 0.5rem 1rem;
  border-bottom: 0.1rem solid #3bed3b;
}
.label-motivo {
  top: -1.5rem;
}
</style>